<template>
    <div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background:var(--light-bg-gray); overflow-y:scroll; z-index: 100;">

        <TemplateDrawer @next="emailBuilt"/>
        <a-modal :visible="showConfirmDialog" :footer="null" @cancel="showConfirmDialog = false">
            <div style="text-align:center">
                <div>
                    <Rive v-if="!sendNow" src="schedule.riv" fit="cover" style="width:100%;" />
                    <img v-else :src="sendNow? sendImg : scheduleImg" style="max-width:270px; width:100%;" />
                </div>
                <div style="text-align:center; font-size:18px" class="mt-3">
                    <span>
                        {{sendNow ? `You are about to send this broadcast now to` : `This scheduled broadcast will be sent to`}}<br/>
                    </span>

                    <span style="display:block; font-size:24px; font-weight:bold; margin-top:10px;">{{broadcastContacts}}</span>
                    <span style="font-weight:bold;">Contacts</span>
                    <template v-if="!sendNow && broadcast.scheduledString">
                        <div style="font-size:18px;" class="mt-4">
                            <div>Scheduled for</div>
                            <div><b>{{broadcast.scheduledString}}</b></div>
                        </div>
                    </template>

                    <small class="send-disclaimer">Number shown might change based on valid emails or non reputable email sources. Please read the following for more information.</small>

                    <div class="dF jC mt-6">
                        <a-button v-if="broadcastContacts === 0" class="ml-2" size="large" @click="showConfirmDialog = false" type="primary">CLOSE</a-button>
                        <a-button  v-else class="ml-2" size="large" @click="submit" :disabled="broadcastContacts < 0" type="primary">SEND</a-button>

                    </div>


                </div>
            </div>
        </a-modal>
        <!-- <div class="dF jSB">
            <h4 style="margin-left: 15px;" class="mb-3 mt-3">{{emailType}}</h4>
            <MoreActions v-if="selectedRowKeys.length" style="margin-right: 1.33rem;" shape="button" buttonText="Bulk Actions" :items="bulkActions" @action="bulkActioned"/>
        </div> -->
		<div class="dF jSB aS">
			<h4 class="pb-2">Add New Broadcast</h4>
			<!-- <div class="px-3 py-1" style="background-color: #FFFFFF; border: 1px solid #e8e8e8;">
				<div>We have <span style="font-weight: bold; color: red;">removed</span> broadcast <span style="font-weight: bold;">Schedule Time</span> to avoid sending it to users while you are editing. Please Reschedule it at your convenience.</div>
			</div> -->
		</div>

        <div :class="[emailType === 'Choose a starting point'? 'contactspage2' : '']" style="background-color: white;">
            <a-steps v-if="current === 1" :current="current" class="mb-5" style="width: 70%; padding-top: 25px; padding-left: 25px;">
                <a-step v-for="item in steps" :key="item.title" :title="item.title">
                    <span slot="description">{{item.description}}</span>
                </a-step>
            </a-steps>

            <a-card v-if="current === 0">
                <a-steps :current="current" class="mb-5" style="width: 70%;  padding-right: 25px;">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title">
                        <span slot="description">{{item.description}}</span>
                    </a-step>
                </a-steps>

                <a-form-model ref="ruleForm" :model="broadcast" :rules="rules">
                    <a-form-model-item ref="name" label="Name this broadcast" prop="name">
                        <a-input
                            v-model="broadcast.name"
                            placeholder="Add broadcast name"
                            size="large"
                            style="width: 70%;"
                        />
                    </a-form-model-item>

                    <a-form-model-item ref="subject" label="Subject line" prop="subject">
                        <a-input
                            v-model="broadcast.subject"
                            placeholder="Add subject line"
                            size="large"
                            style="width: 70%;"
                        />
                    </a-form-model-item>

                    <a-form-model-item ref="preheader" label="Preheader text" prop="preheader">
                        <a-input
                            v-model="broadcast.preheader"
                            placeholder="The preview that's displayed as an email preview"
                            size="large"
                            style="width: 70%;"
                        />
                    </a-form-model-item>

                    <a-form-model-item label="Who is it from?" style="width: 70%;">
                        <a-row :gutter="16">
                            <a-col :span="8">
                                <a-input
                                    :value="sender.name"
                                    size="large"
                                    disabled
                                />
                            </a-col>
                            <a-col :span="16">
                                <a-select
                                    :value="sender.id"
                                    size="large"
                                    @change="selectSender"
                                >
                                    <a-select-option v-for="item in senders" :key="item.id" :value="item.id" :disabled="!item.active">{{item.email}}</a-select-option>
                                </a-select>
                            </a-col>
                        </a-row>
                    </a-form-model-item>
                </a-form-model>

                <div class="mt-5" style="width: 100%; text-align: right;">
                    <a-button @click="cancelBroadcast" class="mr-3" style="width: 100px; height: 35px;">CANCEL</a-button>
                    <a-button @click="nextStep" type="primary" style="width: 100px; height: 35px;">NEXT</a-button>
                </div>

            </a-card>

            <div v-if="current === 1" style="padding: 25px;">


                <a-tabs default-active-key="1">
                    <a-tab-pane key="1">
                        <span slot="tab">
                            Predefined Templates
                        </span>
                        <div class="template-card-container px-2">
                            <NewTemplateCard v-for="(item) in templatesTransformed" :item="item" @click.native="dupNewTemplate(item)" class="mb-5" :key="item.id"/>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2">
                        <span slot="tab">
                            <a-icon type="star" />
                            Your Saved Templates
                        </span>
                        <div class="template-card-container px-2">
                            <TemplateCard v-for="(item) in savedTemplates" :item="item" @click.native="dupTemplate(item)" class="mb-5" :key="item.id"/>
                        </div>
                    </a-tab-pane>
                </a-tabs>

                <div class="mt-5 dF jSB" style="width: 100%; text-align: right;">
                    <a-button @click="current = 0" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
                    <span />
                </div>
            </div>

            <a-card v-if="current === 2">
                <a-steps :current="current" class="mb-5" style="width: 70%; padding-right: 25px;">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title">
                        <span slot="description">{{item.description}}</span>
                    </a-step>
                </a-steps>

                <h5 class="mb-3">Who are you sending this Broadcast to?</h5>

				<a-radio-group v-if="resendingBroadcast" v-model="reMarketAudience" button-style="solid">
					<a-radio-button value="unopened_contacts">
						Unopened Contacts
					</a-radio-button>
					<a-radio-button value="original_contacts">
						Original Contacts
					</a-radio-button>
				</a-radio-group>

                <a-form-model v-else ref="step3form" :model="broadcast" :rules="step3Rules">
                    <a-form-model-item ref="inc" label="Send to All Contacts" prop="allContacts">
                        <a-switch v-model="broadcast.allContacts" />
                    </a-form-model-item>
					<p>Send to </p>
					<div class="mb-4">
						<a-radio-group v-model="isAgent" :disabled="broadcast.allContacts">
							<a-radio-button :value="'all'">
								All
							</a-radio-button>
							<a-radio-button :value="true">
								Realtors
							</a-radio-button>
							<a-radio-button :value="false">
								Non Realtors
							</a-radio-button>
						</a-radio-group>
					</div>
                    <a-form-model-item ref="inc" label="Include Recipient by Tags" prop="tagI_any">
                        <a-select :disabled="broadcast.allContacts" v-model="broadcast.tagI_any" mode="multiple" style="width: 70%" @search="handleSearch" :filter-option="false">
							<a-select-option v-for="(option,optionI) in filteredTags" :key="option+optionI" :value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
                    </a-form-model-item>
                    <a-form-model-item ref="exclude" label="Exclude Recipient by Tags" prop="tagE_any">
                        <div style="width: 100%; color: #9EA0A5;line-height: 1;margin-bottom: 15px;">Exclude anyone in this list from receiving this broadcast</div>
                        <a-select :disabled="broadcast.allContacts" v-model="broadcast.tagE_any" mode="multiple" style="width: 70%" size="large" @search="handleSearch" :filter-option="false">
							<a-select-option v-for="(option,optionI) in filteredTags" :key="option+optionI" :value="option.value">
								{{ option.label }}
							</a-select-option>
						</a-select>
                    </a-form-model-item>
                </a-form-model>
				<a-alert v-if="contactsNotFound" class="mt-3" type="error" show-icon style="width: fit-content;">
					<template slot="message">
						No contacts were found. Please review and try again.
					</template>
				</a-alert>
                <div class="mt-5" style="width: 100%; text-align: right;">
                    <a-button @click="backToEditor" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
                    <a-button @click="lastStep" type="primary" style="width: 100px; height: 35px;">NEXT</a-button>
                </div>

            </a-card>

            <a-card v-if="current === 3">
                <a-steps :current="current" class="mb-5" style="width: 70%; padding-right: 25px;">
                    <a-step v-for="item in steps" :key="item.title" :title="item.title">
                        <span slot="description">{{item.description}}</span>
                    </a-step>
                </a-steps>

				<a-form-model-item class="mt-3">
					<template slot="label">
						Use Send Time Optimization
						<a-tooltip placement="right" overlayClassName="change-tooltip-color">
							<template slot="title" style="width: 400px;">
								Improve your email conversion and open rate by automatically sending emails at the exact moment a recipient views their inbox with Send Time Optimization based on recipients previous engagements.
							</template>
							<a-icon type="question-circle"
								style="font-size: 14px; color: black; margin-left: 2px;" />
						</a-tooltip>
					</template>
	                <a-radio-group v-model="broadcast.useSendTimeOptimization">
	                    <a-radio-button :value="true">
	                        Yes
	                    </a-radio-button>
	                    <a-radio-button :value="false">
	                        No
	                    </a-radio-button>
	                </a-radio-group>
	            </a-form-model-item>

                <h5 class="mb-3">When would you like to send this broadcast?</h5>

                <a-radio-group v-model="sendNow">
                    <a-radio style="display: block; height: 30px; line-height: 30px;" :value="true">
                        Send it now
                    </a-radio>
                    <a-radio style="display: block; height: 30px; line-height: 30px;" :value="false">
                        Schedule a specific time and date
                    </a-radio>
                </a-radio-group>

                <template v-if="!sendNow">
                    <a-form class="mt-2" style="margin-left:1.8rem">
                        <a-form-item label="Date" class="mb-2">
                            <a-date-picker v-model="broadcast.scheduledDate" @change="checkSchedule"    placeholder="Select Date" />
                        </a-form-item>
                        <a-form-item label="Time">
                            <a-time-picker v-model="broadcast.scheduledTime"  @change="checkSchedule" use12-hours :minute-step="10" :default-value="moment('12:00', 'hh:mm A')" format="h:mm a" />
                        </a-form-item>
                        <a-form-item label="Time Zone">
                            <div>
                                <a-select v-model="selectedCountry" style="width: 70%; max-width:300px;" :options="countries" />
                            </div>
                            <div>
                                <a-select  @change="checkSchedule" v-model="broadcast.timezone"  style="width: 70%;  max-width:300px;" >
                                    <a-select-option v-for="(item,itemI) in timezones" :key="itemI" :value="item.value">{{item.label}}</a-select-option>
                                </a-select>
                            </div>
                        </a-form-item>
                    </a-form>
					<a-alert v-if="scheduleError" class="mt-3" type="error" show-icon style="width: fit-content;">
						<template slot="message">
							Error: {{ scheduleError }}
						</template>
					</a-alert>
                </template>

                <div class="mt-5" style="width: 100%; text-align: right;">
					<a-button @click="current = 2" class="mr-3" style="width: 100px; height: 35px;">BACK</a-button>
					<a-tooltip overlayClassName="change-tooltip-color" :title="$p < 40 ? 'You do not have permission to send out Broadcasts' : ''">
						<a-button @click="showConfirm" type="primary" style="width: 100px; height: 35px;"
							:disabled="$p < 40 || (!sendNow && Boolean(scheduleError))">SEND</a-button>
					</a-tooltip>
				</div>
            </a-card>
        </div>
    </div>
</template>

<script>
    import {arrToObj,formatDate,getCookie} from 'bh-mod'
    import TemplateDrawer from '@/components/email/TemplateDrawer'
    import NewTemplateCard from '@/components/email/NewTemplateCard'
	import TemplateCard from '@/components/email/TemplateCard'
    import Rive from '@/components/common/Rive'
    import BHLoading from 'bh-mod/components/common/Loading'
    import moment from 'moment';
    import axios from 'axios'
    import { getTimezones, getCountries } from "@/assets/timezones";


    export default {
        components:{
            TemplateDrawer,NewTemplateCard,TemplateCard,BHLoading,Rive,
        },
        data() {
            const validateAllContacts = (rule, value, callback) => {
                this.$refs.step3form && this.$refs.step3form.validateField('tagI_any');
                callback();
            }
            return {
                selectedCountry:'CA',
            	isAgent:'all',
                sendImg:require('@/assets/sendEmail.svg'),
                scheduleImg:require('@/assets/schedule.svg'),
                contactsNotFound:false,
                showConfirmDialog:false,
                broadcastContacts:-2,
                scheduleError:false,
                form:{
                    leadScoreOpens:1,
                    leadScoreClicks:1,
                },
                recepientLength:0,
                tags:[],
				filteredTags:[],
                email:{},
                sendNow:true,
                duplicate:{
                    name:'',
                    id:'',
                    show:false,
                    load:false
                },
                timeNow:Date.now(),
                mailOpen:'',
                filter:'all',
                baseImage:'',
                loadModal:false,
                taskOpen:'',
                appointmentOpen:'',
                searchMode:false,
                activeTab:'1',
                testEmail:[],
                sender:{
                    id:'',
                    name:'',
                    email:''
                },
                broadcast:{
                    name:'',
                    subject:'',
                    previewText:'',
                    timezone:'-04:00',
                    allContacts:true,
                    tagI_any:[],
                    tagE_any:[],
                    scheduled:0,
                    scheduledString:'',
                    isScheduled:false,
                    scheduledTime:moment().endOf('hour').add(1, 'hour').add(1, 'minutes'),
                    scheduledDate:moment(),
					useSendTimeOptimization: false
                },
				reMarketAudience: 'unopened_contacts',
                rules: {
                    name: [
                        { required: true, message: 'Please input a broadcast name', trigger: 'blur' },
                    ],
                    subject: [
                        { required: true, message: 'Please enter a subject line', trigger: 'blur' }
                    ],
                },
                step3Rules: {
                    allContacts: [
                        { validator:validateAllContacts,trigger: 'change' }
                    ],
                },

                selectedRowKeys: [],
                bulkActions:[
                    {
                        label:'Delete Selected Forms/Surveys',
                        value:'delete'
                    }
                ],

                current: 0,
                steps: [
                    {
                        title: 'Step 1',
                        description: 'Define Broadcast Info'
                    },
                    {
                        title: 'Step 2',
                        description: 'Content Design'
                    },
                    {
                        title: 'Step 3',
                        description: 'Define Recipients'
                    },
                    {
                        title: 'Finish',
                        description: 'Deliver'
                    },
                ],
				templates:[]
            }
        },
        watch:{
            selectedCountry(val){
                let found = this.timezones.find(x => x.value === this.broadcast.timezone)
                if (found) return console.log('FOUND')
                this.broadcast.timezone = this.timezones[0].value
            },
            sendNow(val){
                if (val) {
                    this.broadcast.scheduledString = ''
                }
                else {
                    this.checkSchedule()
                }
            },
            currentStep(){
                this.$store.commit('EMAIL_TYPE', {value:'Add New Broadcast'})
                this.current = 2
            },
        },
        computed:{
            countries(){return getCountries()},
            timezones(){return getTimezones(this.selectedCountry)},
            getTime(){
                // find out if moment object is today
                let day = this.broadcast.scheduledDate.format('dddd')
                let time = this.broadcast.scheduledTime.format('h:mm A')
                if (this.broadcast.scheduledDate.isSame(moment(), 'day')){
                    day = 'Today'
                }

                return `${day} @ ${time}`

            },
            currentStep(){
                return this.$store.state.step
            },
            senders(){
                let senders = this.$store.state.email.senders
                return senders
            },
            emailType(){
                return this.$store.state.emailType
            },
            broadcasts(){
                return this.$store.state.email.broadcasts
            },
			templatesTransformed(){
				if (this.templates && this.templates.length){
					let res = this.templates
					console.log('TEMPLATES TRANSFORED', res)
					let index = res.findIndex(x => x.templateSlug == 'blank-template');
					let blank = res[index];
					res = res.filter(x => x.templateSlug != 'blank-template');
					res = [blank,...res]
					return res
				} else {
					return []
				}
			},
            savedTemplates(){

                let templates = Object.values(this.$store.state.email.templates)

                let customs = []
                templates.forEach( tmp => {
                    if (tmp.instance === this.$store.state.instance.id) customs.push(tmp)
                })

                return customs

            },
            siteURL(){
                return this.$store.state.email.siteURL
            },
            searchOBJ(){
                return this.$store.state.email.searchOBJ
            },
            resultLength(){
                return this.$store.state.email.resultLength
            },
            showingContactMode(){
                return this.listMode === 'all' ? `Showing All Contacts (${this.resultLength} Contacts) `: `Found ${this.resultLength} Contacts `
            },
            newStoreTime(){
                return this.$store.state.email.newStoreTime
            },
            instance(){
                return this.$store.state.instance
            },
            pageSize(){
                return this.$store.state.email.pageSize
            },
            query(){
                return this.$store.getters.query
            },
            dialog(){
                return this.$store.state.email.broadcastModal
            },
            loadingPage:{
                get(){
                    return this.$store.state.email.loadingPage
                },
                set(val){
                    this.$store.commit('LOAD_PAGE',val)
                }
            },
			resendingBroadcast() {
				return this.$route.path.includes('resend')
			}
        },
        methods:{
			handleSearch(value) {
				const inputValue = value.toLowerCase();

				this.filteredTags = this.tags.filter(tag =>
					tag.label.toLowerCase().includes(inputValue)
				);
			},
            selectSender(sender){
                this.sender.id = this.senders[sender].id
                this.sender.name = this.senders[sender].name
                this.sender.email = this.senders[sender].email
            },
            showConfirm(){

                if (this.sendNow){
                    this.broadcast.scheduledString = ''
                } else {
                    this.checkSchedule()
                }

                if (!this.sendNow && Boolean(this.scheduleError)) return this.$message.error('Invalid Time. Please review your fields and try again')
                this.showConfirmDialog = true
            },
            getContactsNum(){
                return new Promise( (res,rej) => {
                    this.broadcastContacts = -1;
					const sendOBJ = JSON.parse(JSON.stringify(this.broadcast));
					if (this.resendingBroadcast) {
						sendOBJ.initialBroadcast = this.$route.params.id;
						sendOBJ.reMarketAudience = this.reMarketAudience;
					}
					if(this.isAgent !== 'all'){
						sendOBJ.isAgent = this.isAgent;
					}
                    this.$api.post(`/broadcasts/:instance/contactscount`,sendOBJ).then( ({data}) => {
                        data = +data || 0
                        this.broadcastContacts = data
                        return res(data)
                    })
                    .catch( (err) => {
                        return res(0)
                    })
                })
            },
            checkSchedule(){

                this.scheduleError = false
                try{
                    let date = this.broadcast.scheduledDate.format('L');
                    let time = this.broadcast.scheduledTime.format('HH:mm');
                    let timezone = this.broadcast.timezone
                    let timeNow = Date.now()

                    let scheduledString = `${date} ${time} ${timezone}`

                    this.broadcast.scheduledString = scheduledString
                    let scheduled = moment(scheduledString)

                    if (scheduled < moment()) this.scheduleError = true
                }catch(err){
                    console.error('[ERROR] - CHECKING SCHEDULEEEEEEEEE ',err)
                    this.scheduleError = true
                }
            },
            backToEditor(){
                this.$store.commit('SHOW_EDIT_TEMPLATE',this.email)
				setTimeout(() => {
					this.current = 1;
				}, 500)
            },
            emailBuilt(email){
                this.email = email
            },
            moment,
            async submit(){
                if (!this.sendNow){
                    if (this.scheduleError) return console.error('time is invalid')
                    // return this.$message.error('Schedule feature is not yet available.')
                }
                let project = JSON.parse(JSON.stringify(this.email))

                project.name = this.broadcast.subject
                project.default = false

                if (project.headers && project.headers.length){
                    project.headers.forEach(head => {
                        head.default = false
                    })
                }
                if (project.footers && project.footers.length){
                    project.footers.forEach(foot => {
                        foot.default = false
                    })
                }
                project.page.footer.default = false
                project.page.header.default = false
                if (project.page.sections && project.page.sections.length){
                project.page.sections.forEach(item => item.default = false)
                }
                let {page = {}, ...sendOBJ} = project
                sendOBJ.pages = [page]

                this.$store.commit('LOAD')

                let {data:prj} = await this.$api.post(`/projects/:instance/clone`,sendOBJ).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})

                if (!prj.id) return this.$store.commit('LOAD',false), this.$message.error('There was a problem saving your email project')
                sendOBJ.SUBJECT = this.broadcast.subject
                sendOBJ.PREVIEW = this.broadcast.preheader

                let {data:html} = await axios.post(`${this.siteURL}/email?final=1`, sendOBJ)

                if (typeof html !== 'string') return this.$store.commit('LOAD',false), this.$message.error('There was a problem creating HTML')
                sendOBJ = JSON.parse(JSON.stringify(this.broadcast))
                sendOBJ.from = this.sender.id
                sendOBJ.project = prj.id
                sendOBJ.prodHtml = html
                sendOBJ.scheduled = this.sendNow ? 0 : new Date(sendOBJ.scheduled).getTime()

				if (this.resendingBroadcast) {
					sendOBJ.initialBroadcast = this.$route.params.id;
					sendOBJ.reMarketAudience = this.reMarketAudience;
				}

				if(this.isAgent !== 'all'){
					sendOBJ.isAgent = this.isAgent;
				}

                this.$api.post(`/broadcasts/:instance`, sendOBJ).then( ({data}) => {
                    this.$store.commit('LOAD', false)
                    if (this.sendNow){
                        data.processing = true
                        this.$store.commit('SET_PROP', {where:['filter'],what:'Processing'})
                    } else {
                        this.$store.commit('SET_PROP', {where:['filter'],what:'Scheduled'})
                    }
                    this.$router.push('/')

                }).catch( err => {
                    this.$store.commit('LOAD', false)
                    this.$message.error('There was an error while dispatching your email')
                })

            },
            async lastStep() {
				if (this.resendingBroadcast) {
					this.contactsNotFound = false
					await this.fetchContactCounts()
					return
				}
				this.$refs.step3form.validate(async valid => {
					this.contactsNotFound = false
					if (valid) {
						this.fetchContactCounts();
					} else {
						return false;
					}
				});
			},

			async fetchContactCounts() {
				this.$store.commit('LOAD', true)
				let num = await this.getContactsNum()
				if (num) this.current = 3
				else {
					this.contactsNotFound = true
					this.$message.error('Sorry no contacts were found for your selection. Please review and try again.')
				}
				this.$store.commit('LOAD', false)
			},

            packageEmail(){

                let fontGen = (font) => {
                    return `<link data-font href="https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')}" rel="stylesheet" type="text/css">`
                }
                let fontGenStyle = (font) => {
                    return `@import url(https://fonts.googleapis.com/css?family=${font.name.split(' ').join('+')}:${font.weights.join(',')});`
                }


                if (!this.dialog.data.html) return this.$message.error('No HTML found')
                if (!this.broadcast.subject.trim()) return this.$message.error('Please fill out Subject')

                let emailHTML = this.dialog.data.html
                let html = document.createElement('html')
                html.innerHTML = emailHTML

                let parentNode = html.querySelector('body')
                let refChild = html.querySelector('body').children[0]
                let previewDOM = document.createElement('div')

                previewDOM.innerHTML = `<div style="display:none;font-size:1px;color:#ffffff;line-height:1px;max-height:0px;max-width:0px;opacity:0;overflow:hidden;">
                                            ${this.broadcast.preheader}
                                        </div>`

                parentNode.insertBefore(previewDOM, refChild)
                html = html.innerHTML
                html = `<!DOCTYPE html>↵<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">` + html + `</html>`
                html = html.replace(/\n/ig, '');
                html = html.replace('↵','')
                html = html.replace('https://mjml.io','')
                html = html.replace('[[SUBJECT]]',this.broadcast.subject)
                let fields = [
                    {search:/%%COMMUNITYNAME%/gi, replace:this.instance.name},
                    {search:/%%CONTACT_INFO%/gi, replace:`${this.instance.address}, ${this.instance.city} ${this.instance.region}`},
                    {search:/%%name/gi, replace:'%recipient_name'},
                    {search:/%%fname%/gi, replace:'%recipient.firstName%'},
                    {search:/%%lname%/gi, replace:'%recipient.lastName%'},
                    {search:/%%email%/gi, replace:'%recipient_email%'},
                    {search:/%%5/gi, replace:'%recipient.5'},
                ]

                let fonts = this.dialog.data.options && this.dialog.data.options.fonts? this.dialog.data.options.fonts : false
    //
    //           <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
    //           <style type="text/css">
    //                 @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
    //           </style>
    //     <!--<![endif]-->
                if (fonts){
                    let fontHTML = fonts.map(x => fontGen(x)).join(' ')
                    fontHTML = fontHTML  + `<style data-font>${fonts.map(x => fontGenStyle(x)).join(' ')}</style>`
                    html = html.replace('<style', `<!--[if mso]>
                                        <xml>
                                        <o:OfficeDocumentSettings>
                                        <o:AllowPNG/>
                                        <o:PixelsPerInch>96</o:PixelsPerInch>
                                        </o:OfficeDocumentSettings>
                                        </xml>
                                        <![endif]-->
                                    <!--[if lte mso 11]>
                                        <style type="text/css">
                                        .outlook-group-fix { width:100% !important; }
                                        </style>
                                        <![endif]--><!--[if !mso]><!-->${fontHTML}<!--<![endif]--> <style`)
                }

                fields.forEach( ({search,replace}) => {
                    html = html.replace(search,replace)
                })

                return html
            },
            nextStep(){
                if (this.current == 0){
                    this.$refs.ruleForm.validate(valid => {
                        if (valid) {
							if(this.resendingBroadcast) {
								this.fetchTemplate()
							} else {
								this.current = 1
								this.$store.commit('EMAIL_TYPE', {value: 'Choose a starting point'})
								if (!this.templates.length){
									axios.post(`${this.$store.state.email.siteURL}/list/templates-email`).then( ({data}) => {
										this.templates = Object.values(data)
									})
								}
							}
                        } else {
                            return false;
                        }
                    });
                }
            },
            cancelBroadcast(){
                this.$router.push(`/`)
                this.$store.commit('EMAIL_TYPE', {value:'Drafts'})
            },
            async bulkActioned({key,keyPath,val}){
                let self = this

                if (key == 'delete') {
                    this.$confirm({
                        title: "Delete",
                        content: h => <div>Do you want to delete these forms/surveys?</div>,
                        okText: 'Delete',
                        okType: 'danger',
                        cancelText: 'Cancel',
                        centered: true,
                        onOk() {
                            for (var i=0; i<self.selectedRowKeys.length; i++) {
                                let id = self.selectedRowKeys[i]
                            }
                            setTimeout(() => {
                                self.selectedRowKeys = []
                            }, 1000);
                        },
                        onCancel() {
                            console.log('Cancel')
                        }
                    })
                }
            },
            formatDate,
            getDeliveredPerc({counts,contacts}){
                let {delivered = 0} = counts
                return (delivered / contacts.length) * 100
            },
            itemMenuClick( { item, key, keyPath },broadcast) {
                if (key === 'dup'){
                    this.dupTemplate(broadcast.email)
                } else if (key === 'report'){
                    this.$message.error('This functionality is currently not available')
                }
            },
            async dupTemplate({id}){
                this.$store.commit('LOAD',true)

                let {data} = await this.$api.get(`/projects/:instance/${id}`).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})

                let {id:id2,_id, pages = [],...finalObj} = data

                let page = pages[0]

                if (!page) {
                    this.$message.error('There is a problem with this Template, Please choose another!')
                    return this.$store.commit('LOAD', false)
                }

                delete page._id
                delete page.id

				if(page.header){
					delete page.header.id
					delete page.header._id
					page.header.id = 'add_header'
				}
                if (page.footer){

                    delete page.footer.id
                    delete page.footer._id
					page.footer.id = 'add_footer'
                }

                let {sections = []} = page
                sections = sections.map(({_id,id,...sec}) => {
					sec.id = 'add_' + id
					return sec
				})

                page.sections = sections

                finalObj.page = page
                finalObj.SUBJECT = this.broadcast.subject
                finalObj.PREVIEW = this.broadcast.preheader
                finalObj.broadcast = this.broadcast
                this.$store.commit('SHOW_EDIT_TEMPLATE',finalObj)

            },
			async dupNewTemplate(item){
                this.$store.commit('LOAD',true)
				let __bhjt = getCookie('__bhjt')
				let sendObj = {
					name: item.name,
					template: item.templateSlug,
					type:'email',
					__bhjt,
					_global: item._global
				}
				console.log('DUPLICATING NEW TEMPLATE', sendObj)

                let {data} = await axios.post(this.siteURL + `/create-email/` + this.$store.state.instance.id, sendObj)

                let {id:id2,_id, pages = [],...finalObj} = data

                let page = pages[0]

                if (!page) {
                    this.$message.error('There is a problem with this Template, Please choose another!')
                    return this.$store.commit('LOAD', false)
                }

                delete page._id
                delete page.id

                delete page.header.id
                delete page.header._id
				page.header.id = 'add_header'
                if (page.footer){

                    delete page.footer.id
                    delete page.footer._id
					page.footer.id = 'add_footer'
                }

                let {sections = []} = page
				let index = 0
                sections = sections.map(({_id,id,...sec}) => {
					sec.id = 'add_' + Date.now() + index
					index++
					return sec
				})

                page.sections = sections

                finalObj.page = page
                finalObj.SUBJECT = this.broadcast.subject
                finalObj.PREVIEW = this.broadcast.preheader
                finalObj.broadcast = this.broadcast
                this.$store.commit('SHOW_EDIT_TEMPLATE',finalObj)

            },
            closeDuplicate(){
                if (this.duplicate.load) return

                this.duplicate = {
                    show:false,
                    name:'',
                    id:''
                }
            },
            removeSearch({srch,searchKey}){
                this.$store.commit('REMOVE_SEARCH_PARAM',{srch,searchKey})
            },

            onOk(value) {
                console.log('onOk: ', value)
            },

			fetchTemplate() {
				this.$store.commit('LOAD',true)
				this.$api.get(`/projects/:instance/${this.broadcast.project}`).then(({ data: email }) => {
					email.page = email.pages[0]
					delete email.pages
					this.email = email
					email.SUBJECT = this.broadcast.subject
					email.PREVIEW = this.broadcast.preheader
					this.$store.commit('SHOW_EDIT_TEMPLATE', email)
					this.$store.commit('LOAD',false)
				}).catch(() => {
					this.$store.commit('LOAD',false)
				})
			}
        },
        mounted(){
            window.moment = moment
        },
        created() {

            this.$store.commit('EMAIL_TYPE', {value:'Add New Broadcast'})

            this.$api.get(`/tags/:instance?type=contacts`).then( ({data}) => {
				let tags = data.filter(x => x.type === "contacts").map( ({id,name}) => ({label:name,value:id}) )
                this.tags = JSON.parse(JSON.stringify(tags))
				this.filteredTags = JSON.parse(JSON.stringify(tags))
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})

            this.broadcast.name = `Broadcast created ${formatDate(Date.now())}`

			let id = this.$route.params.id

			if (id && this.resendingBroadcast && this.broadcasts[id]) {
				this.broadcast = { ...this.broadcast, ...JSON.parse(JSON.stringify(this.broadcasts[id])) }
				this.broadcast.name = 'Resend ' + this.broadcasts[id].name
			}

            let senders = Object.values(this.$store.state.email.senders)
            if (senders && senders.length){
                this.sender = {
                    id:senders[0].id,
                    name:senders[0].name,
                    email:senders[0].email
                }
            }
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss" scoped>
    .send-disclaimer{
        line-height: 1.2;
        display: block;
        font-size: 12px;
        max-width: 400px;
        margin: 10px auto;
        color: var(--med-gray);
    }
    .contactspage2{
        @media screen and (min-width:567px){
            max-width: calc(100% - 35px) !important;
        }
    }
    .contacts-page{
        @media screen and (min-width:567px){
            max-width: 70%;
        }
    }
    .aB{
        align-items: baseline;
    }

    .row-mb{
        [class^=ant-col]{
            margin-bottom:20px;
        }
    }



</style>

<style lang="scss">
    .template-card-container{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        gap:20px;
        @media screen and (min-width:900px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (min-width:1600px){
            grid-template-columns: repeat(5, 1fr);
        }
    }
    .email-template-cards{
        .ant-card{
            &:hover{

            }
        }
        .ant-card-actions{
            background:var(--light-purple);
            span{
                color:var(--purple);
            }
        }
        .ant-card-body{
            // background:#fff5e8;
            background: white;
            padding: 0;
        }
        .ant-card-cover{
            height: 300px;
            overflow: hidden;
            position:relative;
            background:#eaedf2;
            img{
                position:absolute;
                top:0;
                width:100%;
                left:0;
                transition: all .6s ease-out;
            }
        }
        .ant-card{
            &:hover{
                .ant-card-cover img{
                    transform: translateY(calc(-100% + 301px));
                }
            }
        }
    }
</style>
